import { BaseEndpoint } from "./Base"

export default class CourseProgressReportEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.progressReports"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/progress_reports`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/progress_reports`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  show({ courseId, reportId, params = {}, options = {} } = {}) {
    this.verifyData("show", { courseId, reportId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/progress_reports/${reportId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
