import { BaseEndpoint } from "./Base"

export default class UserResetPasswordEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.events"
  }

  index({ userId, params = {}, options = {} } = {}) {
    this.verifyData("index", { userId })

    return this.action({
      method:  "get",
      url:     `/users/${userId}/events`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

}
