import { BaseEndpoint } from "./Base"

export default class CourseLearningContentClassroomProgressReportEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.learningContents.classroomProgressReports"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/learning_contents/classroom_progress_reports`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
