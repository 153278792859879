import { BaseEndpoint } from "./Base"

export default class CourseLearningContentModuleEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.learningContents.modules"
  }

  index({ courseId, contentType, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId, contentType })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/learning_contents/${contentType}/course_modules`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
