import { BaseEndpoint } from "./Base"

export default class CourseTopicEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.topics"
  }

  show({ courseId, topicId, params = {}, options = {} } = {}) {
    this.verifyData("show", { courseId, topicId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/course_topics/${topicId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
