import { isAfter } from "date-fns"

export const dateAfter = {
  validate: (value, { dateReference } = {}) => {
    const valueDate = new Date(value)
    const reference = new Date(dateReference)

    return isAfter(valueDate, reference)
  },

  params: ["dateReference"],
}