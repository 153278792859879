import { BaseEndpoint } from "./Base"

export default class CourseModuleEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.modules"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/course_modules`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/course_modules`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ courseId, moduleId, params = {}, options = {} } = {}) {
    this.verifyData("update", { courseId, moduleId })

    return this.action({
      method:  "patch",
      url:     `/courses/${courseId}/course_modules/${moduleId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ courseId, moduleId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { courseId, moduleId })

    return this.action({
      method:  "delete",
      url:     `/courses/${courseId}/course_modules/${moduleId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
