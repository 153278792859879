import CatalogCategory from "./CatalogCategory"
import CatalogCertificate from "./CatalogCertificate"
import CatalogCertificatePreview from "./CatalogCertificatePreview"
import CatalogCourseLearningContentType from "./CatalogCourseLearningContentType"
import CatalogTeam from "./CatalogTeam"
import Category from "./Category"
import Certificate from "./Certificate"
import ContentCard from "./ContentCard"
import Course from "./Course"
import CourseCertificate from "./CourseCertificate"
import CourseCollaborator from "./CourseCollaborator"
import CourseDuplicate from "./CourseDuplicate"
import CourseLearningContentClassroomProgressReport from "./CourseLearningContentClassroomProgressReport"
import CourseLearningContentModule from "./CourseLearningContentModule"
import CourseSubscription from "./CourseSubscription"
import CourseSubscriptionBatch from "./CourseSubscriptionBatch"
import CourseSupply from "./CourseSupply"
import CourseTopic from "./CourseTopic"
import CourseModule from "./CourseModule"
import CourseModuleTopic from "./CourseModuleTopic"
import CourseProgressReport from "./CourseProgressReport"
import CourseReorder from "./CourseReorder"
import CourseStatistic from "./CourseStatistic"
import DataAccessLog from "./DataAccessLog"
import LearningContent from "./LearningContent"
import MonitoringCourseSubscription from "./MonitoringCourseSubscription"
import FormSelectionProcessSubscription from "./FormSelectionProcessSubscription"
import FormSelectionProcessSubscriptionQuestion from "./FormSelectionProcessSubscriptionQuestion"
import OrganizationSection from "./OrganizationSection"
import PermittedCourses from "./PermittedCourses"
import Registration from "./Registration"
import ReportCoursePersonalDataAccess from "./ReportCoursePersonalDataAccess"
import ReportSelectionProcessConfig from "./ReportSelectionProcessConfig"
import ReportSelectionProcessConfigSelectionProcess from "./ReportSelectionProcessConfigSelectionProcess"
import ReportUser from "./ReportUser"
import ReportUserDataExport from "./ReportUserDataExport"
import Role from "./Role"
import SearchCategory from "./SearchCategory"
import SearchCertificate from "./SearchCertificate"
import SearchTeam from "./SearchTeam"
import SearchOwner from "./SearchOwner"
import Section from "./Section"
import SectionContent from "./SectionContent"
import SelectionProcess from "./SelectionProcess"
import SelectionProcessDashboard from "./SelectionProcessDashboard"
import SelectionProcessEnrolmentPhase from "./SelectionProcessEnrolmentPhase"
import SelectionProcessPhase from "./SelectionProcessPhase"
import SelectionProcessPhaseUserSensitive from "./SelectionProcessPhaseUserSensitive"
import SubscriptionEvent from "./SubscriptionEvent"
import Team from "./Team"
import TopicClassroomPresence from "./TopicClassroomPresence"
import TopicClassroomPresenceBatch from "./TopicClassroomPresenceBatch"
import TopicStatistic from "./TopicStatistic"
import User from "./User"
import UserConfirmation from "./UserConfirmation"
import UserEmail from "./UserEmail"
import UserEvent from "./UserEvent"
import UserOrganization from "./UserOrganization"
import UserPassword from "./UserPassword"
import UserResendConfirmation from "./UserResendConfirmation"
import UserResetPassword from "./UserResetPassword"
import UserSensitiveData from "./UserSensitiveData"

const endpoints = /** @type {const} */ ({
  CatalogCategory,
  CatalogCertificate,
  CatalogCertificatePreview,
  CatalogCourseLearningContentType,
  CatalogTeam,
  Category,
  Certificate,
  ContentCard,
  Course,
  CourseCertificate,
  CourseCollaborator,
  CourseDuplicate,
  CourseLearningContentClassroomProgressReport,
  CourseLearningContentModule,
  CourseSubscription,
  CourseSubscriptionBatch,
  CourseSupply,
  CourseTopic,
  CourseModule,
  CourseModuleTopic,
  CourseProgressReport,
  CourseReorder,
  CourseStatistic,
  DataAccessLog,
  LearningContent,
  MonitoringCourseSubscription,
  FormSelectionProcessSubscription,
  FormSelectionProcessSubscriptionQuestion,
  OrganizationSection,
  PermittedCourses,
  Registration,
  ReportCoursePersonalDataAccess,
  ReportSelectionProcessConfig,
  ReportSelectionProcessConfigSelectionProcess,
  ReportUser,
  ReportUserDataExport,
  Role,
  SearchCategory,
  SearchCertificate,
  SearchTeam,
  SearchOwner,
  Section,
  SectionContent,
  SelectionProcess,
  SelectionProcessDashboard,
  SelectionProcessEnrolmentPhase,
  SelectionProcessPhase,
  SelectionProcessPhaseUserSensitive,
  SubscriptionEvent,
  Team,
  TopicClassroomPresence,
  TopicClassroomPresenceBatch,
  TopicStatistic,
  User,
  UserConfirmation,
  UserOrganization,
  UserEmail,
  UserEvent,
  UserPassword,
  UserResendConfirmation,
  UserResetPassword,
  UserSensitiveData,
})

export default endpoints
