import { BaseEndpoint } from "./Base"

export default class TopicStatisticEndpoint extends BaseEndpoint {
  static get selector() {
    return "topics.statistics"
  }

  show({ topicId, params = {}, options = {} } = {}) {
    this.verifyData("show", { topicId })

    return this.action({
      method:  "get",
      url:     `/course_topics/${topicId}/statistics`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
