import { BaseEndpoint } from "./Base"

export default class CourseModuleTopicEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.modules.topics"
  }

  show({ courseId, moduleId, topicId, params = {}, options = {} } = {}) {
    this.verifyData("show", { courseId, moduleId, topicId })

    return this.action({
      method:  "get",
      url:     `/courses/${courseId}/course_modules/${moduleId}/course_topics/${topicId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  create({ courseId, moduleId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId, moduleId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/course_modules/${moduleId}/course_topics`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ courseId, moduleId, topicId, params = {}, options = {} } = {}) {
    this.verifyData("update", { courseId, moduleId, topicId })

    return this.action({
      method:  "patch",
      url:     `/courses/${courseId}/course_modules/${moduleId}/course_topics/${topicId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  delete({ courseId, moduleId, topicId, params = {}, options = {} } = {}) {
    this.verifyData("delete", { courseId, moduleId, topicId })

    return this.action({
      method:  "delete",
      url:     `/courses/${courseId}/course_modules/${moduleId}/course_topics/${topicId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
