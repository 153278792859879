import { copyToClipboard } from "../../helpers/dom-manipulation"

const VAR_TRANSLATE_Y = "--_v-copy-translate-y"
const VAR_TRANSLATE_X = "--_v-copy-translate-x"

/**
 * @param {HTMLElement} el
 * @param {string} datasetKey
 */
export function copyWithFeedback(el, datasetKey, attachmentConfig) {
  copyToClipboard(el.dataset[datasetKey])
  attachFeedback(el, attachmentConfig)
}

/** @param {HTMLElement} el */
function attachFeedback(el, attachmentConfig) {
  const existingElement = el.querySelector("[data-copy-feedback]")
  existingElement?.remove()

  el.style.position = "relative"

  const span = document.createElement("span")
  span.dataset.copyFeedback = ""
  span.innerText = "Link copiado!"

  styleFeedback(span, attachmentConfig)

  el.appendChild(span)

  const translateX = `var(${VAR_TRANSLATE_X}, 4px)`
  const translateY = `var(${VAR_TRANSLATE_Y}, -50%)`

  /** @type {Keyframe[]} */
  const animationKeyframes = [
    { transform: `translate(${translateX}, ${translateY})`, opacity: 0 },
    { transform: `translate(0, ${translateY})`, opacity: 1, offset: 0.1 },
    { transform: `translate(0, ${translateY})`, opacity: 1, offset: 0.9 },
    { transform: `translate(${translateX}, ${translateY})`, opacity: 0 },
  ]
  /** @type {KeyframeAnimationOptions} */
  const animationOptions = {
    duration: 2000,
  }

  const animation = span.animate(animationKeyframes, animationOptions)

  animation.finished.then(() => {
    if (!document.contains(span)) return
    span.remove()
    el.style.removeProperty("position")
  })
}

/** @param {HTMLElement} el */
function styleFeedback(el, attachmentConfig) {
  el.style.color = "black"
  el.style.whiteSpace = "nowrap"
  el.style.position = "absolute"
  el.style.opacity = 0

  const spacing = "0.5rem"
  const positionWithSpacing = `calc(100% + ${spacing})`

  if (attachmentConfig.top) {
    el.style.bottom = positionWithSpacing
    el.style.right = "0px"
    el.style.setProperty(VAR_TRANSLATE_Y, "0")
    el.style.setProperty(VAR_TRANSLATE_X, "-4px")
    //
  } else if (attachmentConfig.bottom) {
    el.style.top = positionWithSpacing
    el.style.right = "0px"
    el.style.setProperty(VAR_TRANSLATE_Y, "0")
    el.style.setProperty(VAR_TRANSLATE_X, "-4px")
    //
  } else if (attachmentConfig.left) {
    el.style.top = "50%"
    el.style.right = positionWithSpacing
    el.style.setProperty(VAR_TRANSLATE_Y, "-50%")
    el.style.setProperty(VAR_TRANSLATE_X, "-4px")
    //
  } else {
    el.style.top = "50%"
    el.style.left = positionWithSpacing
  }
}
