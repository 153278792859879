import { BaseEndpoint } from "./Base"

export default class CatalogCourseLearningContentTypeEndpoint extends BaseEndpoint {
  static get selector() {
    return "catalog.courses.learningContentTypes"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/catalog/courses/${courseId}/learning_content_types`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
