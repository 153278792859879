import { BaseEndpoint } from "./Base"

export default class CourseDuplicateEndpoint extends BaseEndpoint {
  static get selector() {
    return "courses.duplicates"
  }

  create({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("create", { courseId })

    return this.action({
      method:  "post",
      url:     `/courses/${courseId}/duplicates`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
