import { BaseEndpoint } from "./Base"

export default class MonitoringCourseSubscriptionEndpoint extends BaseEndpoint {
  static get selector() {
    return "monitoring.courses.subscriptions"
  }

  index({ courseId, params = {}, options = {} } = {}) {
    this.verifyData("index", { courseId })

    return this.action({
      method:  "get",
      url:     `/monitoring/courses/${courseId}/subscriptions`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
