import { BaseEndpoint } from "./Base"

export default class LearningContentEndpoint extends BaseEndpoint {
  static get selector() {
    return "learningContents"
  }

  create({ params = {}, options = {} } = {}) {
    return this.action({
      method:  "post",
      url:     "/learning_contents",
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }

  update({ contentId, params = {}, options = {} } = {}) {
    this.verifyData("update", { contentId })

    return this.action({
      method:  "patch",
      url:     `/learning_contents/${contentId}`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
