import { BaseEndpoint } from "./Base"

export default class TopicClassroomPresenceBatchEndpoint extends BaseEndpoint {
  static get selector() {
    return "topics.classroomsPresence.batches"
  }

  create({ topicId, params = {}, options = {} } = {}) {
    this.verifyData("create", { topicId })

    return this.action({
      method:  "post",
      url:     `/course_topics/${topicId}/classroom_presences/batches`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
