import { BaseEndpoint } from "./Base"

export default class UserResetPasswordEndpoint extends BaseEndpoint {
  static get selector() {
    return "users.resetPasswords"
  }

  create({ userId, params = {}, options = {} } = {}) {
    this.verifyData("create", { userId })

    return this.action({
      method:  "post",
      url:     `/users/${userId}/reset_passwords`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
