import { BaseEndpoint } from "./Base"

export default class TopicClassroomPresenceEndpoint extends BaseEndpoint {
  static get selector() {
    return "topics.classroomsPresence"
  }

  index({ topicId, params = {}, options = {} } = {}) {
    this.verifyData("index", { topicId })

    return this.action({
      method:  "get",
      url:     `/course_topics/${topicId}/classroom_presences`,
      params,
      options: {
        ...options,

        admin: true
      }
    })
  }
}
