export const IntersectDirective = {
  /** @param {HTMLElement} el  */
  bind(el, { modifiers, value }) {
    const root = modifiers.parent ? el.parentElement : document

    el.observer = new IntersectionObserver(entries => {
      const entryOnView = entries.some((entry) => entry.isIntersecting)

      if (!entryOnView) return

      value()
      //
    }, {
      root,
      rootMargin: "0px",
      threshold:  1.0,
    })

    el.observer.observe(el)
  },

  unbind(el) {
    el.observer?.disconnect()
  },
}
